// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import styles from './GalleryItem.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export default function GalleryItem({url, title, innerUrl}) {
  return (
    <Link to={innerUrl}>
      <div className={styles.galleryItem}>
        <img src={url} alt={title} className={cx('galleryItem__img')}/>
        <div className={styles.overlay}>
          <div className={cx('overlay__text')}>
            <h2>{title}</h2>
          </div>
        </div>
      </div>
    </Link>
  );
}
