import ProjectsGallery from '../ProjectsGallery/ProjectsGallery';
import React from 'react';
import styles from './Portfolio.module.scss';

export default function Portfolio() {
  return (
    <div className={styles.portfolio}>
      <ProjectsGallery/>
    </div>
  );
}