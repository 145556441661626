// @flow
import React from 'react';
import Layout from '../components/Layout/Layout';
import Portfolio from '../components/Portfolio/Portfolio';

const PortfolioTemplate = ({location: {pathname}}) => {
  return (
    <Layout pathname={pathname} incomingTitle={'Portfolio'}>
      <Portfolio/>
    </Layout>
  );
};

export default PortfolioTemplate;
