// @flow strict
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import GalleryItem from './GalleryItem/GalleryItem';
import styles from './ProjectsGallery.module.scss';
import useSiteMetadata from '../../hooks/use-site-metadata';

export default function ProjectsGallery() {
  const {projects} = useSiteMetadata();

  const data = useStaticQuery(graphql`query Projects {
            allCloudinaryMedia(sort: {fields: public_id}, filter: {public_id: {regex: "/projects/"}}) {
              edges {
                node {
                  public_id
                  secure_url
                }
              }
            }
        }`);

  function renderImages(edges: any) {
    return edges.map((image, index) => {
      const imageName = image.node.public_id.split('/')[1];
      const metadata = projects.find(item => item.id === imageName);
      return (
        <GalleryItem key={`${index}-cl`} url={image.node.secure_url} title={metadata.label} innerUrl={metadata.path}/>
      );
    });
  }

  return (
    <div>
      <div className={styles.galleryGrid}>
        {renderImages(data.allCloudinaryMedia.edges)}
      </div>
    </div>
  );
}
